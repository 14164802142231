import React, { useEffect, useState } from "react";
import "./style.css";
import supabase from "./supabase";

const CATEGORIES = [
  { name: "On Linkedin" },
  { name: "On Facebook" },
  { name: "On Instagram" },
  { name: "On Tic Tok" },
  { name: "On campus" },
  { name: "Word of mouth" },
];

function CallToAction() {
  // waitlist to be used by different componets we had to place this on top
  const [waitlist, setWaitlist] = useState([]);
  function Loader() {
    return <p>loading</p>;
  }
  useEffect(function () {
    async function getWaitlist() {
      const { data: waitlist, error } = await supabase
        .from("waitlist")
        .select("id");

      console.log(waitlist);
    }
    getWaitlist();
  }, []);

  const [fullName, setFullname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [heardWhere, setheardWhere] = useState("");

  // to prevent reloading once user clicked post button
  const [isUploading, setIsUploading] = useState(false);

  async function handelSubmit(e) {
    // 1 prevent browser reaload

    e.preventDefault();
    console.log(fullName, number, email, heardWhere);

    // 2 check if the data is valid, if so insert
    if (fullName && email && heardWhere && fullName <= 100)
      console.log("there is valid data");

    //3 upload the fact to supabase and receive the new fact obhect and display on UI
    //setIsUploading(true);
    const { data: newWaitlist, error } = await supabase
      .from("waitlist")
      .insert({ fullName, number, email, heardWhere });

    //setIsUploading(false);
    // //4 add the new fact to the UI: add the fact to state
    //setWaitlist((waitlist) => [newWaitlist[0], ...waitlist]);

    //5 Reset input fields
    setFullname("");
    setNumber("");
    setEmail("");
    setheardWhere("");
  }

  return (
    <section className="section-cta" id="cta-scrolling">
      <div className="container">
        <div className="cta">
          <div className="cta-text-box">
            <h2 className="heading-secondary">
              Gain FREE beta access to this wisdom mine!
            </h2>
            <p className="cta-text">
              <p>
                Q: Do you want to take your professional development to the next
                level?
              </p>
              <p>
                A: Look no further than this incredible platform! By joining our
                wait list, you'll gain access to a world of opportunities to
                accelerate your career and achieve your goals. Grow, learn, and
                connect with like-minded individuals in your industry.
              </p>
              {/* We are integrating AI
              engines filled with wisdom to accelerate your career.With a
              wealth of resources at your fingertips, you'll be able to hone
              your skills, gain new insights, and take your career to the next
              wait list today and start your journey to success!*/}
            </p>

            <form className="cta-form" onSubmit={handelSubmit}>
              <div>
                <label for="full-name">Full Name</label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="John Smith"
                  required
                  value={fullName}
                  onChange={(e) => setFullname(e.target.value)}
                  disabled={isUploading}
                />
              </div>

              <div>
                <label for="phone-number">Phone Number</label>
                <input
                  id="phone-number"
                  type="tel"
                  placeholder="+x xxx xxxxxxx"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  disabled={isUploading}
                />
              </div>
              <div>
                <label for="email"> Email Id</label>
                <input
                  id="email"
                  type="email"
                  placeholder="me@email.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isUploading}
                />
              </div>

              <div>
                <label for="select-where"> How did you hear about us! </label>
                <select
                  id="select-where"
                  value={heardWhere}
                  onChange={(e) => setheardWhere(e.target.value)}
                  disabled={isUploading}
                  required
                >
                  <option value=""> Choose an option </option>
                  {CATEGORIES.map((cat) => (
                    <option key={cat.name} value={cat.name}>
                      {cat.name.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="btn-cta">
                <button className="btn btn--form" disabled={isUploading}>
                  Join Waitlist!
                </button>
              </div>
            </form>
          </div>
          <div
            className="cta-img-box"
            role="img"
            aria-label="Image from unspalsh- linkedin solutions"
          ></div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
