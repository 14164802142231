import React from "react";
import "./style.css";

function HeaderSection() {
  function scrollToAbout() {
    window.scroll({
      top: document.getElementById("cta-scrolling").offsetTop,
      behavior: "smooth",
    });
  }

  function scrollToServices() {
    window.scroll({
      top: document.getElementById("services-sec").offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <header className="header">
      <img
        className="logo"
        alt="CCube logo"
        src="content/img/C Cube logo cropped.png"
      />
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li>
            <a className="main-nav-link" href="#" onClick={scrollToServices}>
              Services
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="./contentlib.html">
              Content Library
            </a>
          </li>

          <li>
            <a className="main-nav-link" href="./aboutus.html">
              About Us
            </a>
          </li>
          <li>
            <a
              className="main-nav-link nav-cta"
              href="#"
              onClick={scrollToAbout}
            >
              Get in touch!
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default HeaderSection;
