import React from "react";
import "./style.css";
//import HeaderSection from "./Headersection";

function HeroSection() {
  function scrollToAbouthero() {
    window.scroll({
      top: document.getElementById("cta-scrolling").offsetTop,
      behavior: "smooth",
    });
  }
  function scrollTolearnmorehero() {
    window.scroll({
      top: document.getElementById("vplayer-id").offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <section className="Section-hero">
      <div className="hero">
        <div className="hero-text-box">
          <h1 className="heading-primary">
            Non Academic Operations that Drive
            <span className="colortag"> Student Success!</span>
            {/* <!-- by becoming professionals in their field. Our technology platform is designed 
        to help individuals accelerate their career
         by becoming professionals in their chosen field.
          We understand that the job market is highly competitive and 
          that many people struggle to advance their careers without the 
          right guidance and resources. That's why we've created a 
          platform that provides individuals with everything they need to
           succeed - from personalized learning pathways and mentorship
            opportunities to access to exclusive job listings and 
            career advice. By leveraging the power of technology 
            and expert insights, our platform enables individuals
             to take control of their careers and achieve their 
             goals faster than ever before. Whether you're a recent g
             raduate just starting out or a mid-career professional 
             looking to make a change, our platform has
              something to offer. Join us today and start your
               journey towards professional success!" --> */}
          </h1>
          <p className="hero-description">
            We help build foundation for student career success with
            streamlining all non acedamic operations: automated work flows for
            job applications, actionable personal insights, externships,
            community of industry experts all in one platfom.
          </p>
          <a
            href="#"
            className="btn btn--full margin-rihgt-sm"
            onClick={scrollToAbouthero}
          >
            Get in touch!
          </a>
          <a
            href="#"
            className="btn btn--outline"
            onClick={scrollTolearnmorehero}
          >
            Learn more &darr;
          </a>
          <div className="mentored-students">
            <div className="student-imgs">
              <img src="content/img/customers/customer-1.jpg" alt="Student" />
              <img src="content/img/customers/customer-2.jpg" alt="Student" />
              <img src="content/img/customers/customer-3.jpg" alt="Student" />
              <img src="content/img/customers/customer-4.jpg" alt="Student" />
              <img src="content/img/customers/customer-5.jpg" alt="Student" />
              <img src="content/img/customers/customer-6.jpg" alt="Student" />
              <p className="student-img-text">
                <span>50+</span> students mentored this year!
              </p>
            </div>
          </div>
        </div>

        <div className="hero-image-box">
          <img
            src="content/img/hero.png"
            className="hero-image"
            alt="women enjoying food, meals in a container, and food bowls on a table"
          />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
