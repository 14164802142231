import React from "react";
import "./style.css";

function FotterPage() {
  return (
    <section className="footer-line">
      <div class="container-footer grid--footer">
        <div class="logo-col">
          <a href="https://joinccube.com/" class="footer-logo">
            <img
              class="logo"
              alt="Omnifood logo"
              src="content/img/C Cube logo cropped.png"
            />
          </a>
          <p class="copyright">
            Copyright &copy; <span class="year">2023</span> by C Cube, Inc. All
            rights reserved.
          </p>
        </div>

        <div className="social-links">
          <a href="https://facebook.com">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </section>
  );
}

export default FotterPage;
