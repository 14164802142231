import supabase from "./supabase";
import "./style.css";
import HeaderSection from "./Headersection";
import HeroSection from "./HeroSection";
import CallToAction from "./CallToAction";
import FotterPage from "./Footer";
import ResourcesSection from "./ResourcesSection";
import Featuredin from "./Featuredin";
import VideoPlayer from "./LearnmoreYT";
import VimeoPlayer from "./Learnmore";

function App() {
  return (
    <>
      <body>
        <HeaderSection />
        <main>
          <HeroSection />
          <Featuredin />
          <VideoPlayer />
          <ResourcesSection />
          <CallToAction />
        </main>
        <FotterPage />
      </body>
    </>
  );
}

export default App;
