import "./style.css";

function Featuredin() {
  // waitlist to be used by different componets we had to place this on top
  return (
    <section className="section-featured-in" id="section-featured-in-id">
      <div className="container" id="container-featuredin-id">
        <h2 className="featuredin-text" id="featuredin-text-id">
          As featured In
        </h2>
        <div className="logo-pics" id="logo-pics-id">
          <img
            src="content/img/logos/dd.png"
            className="featuredin-img"
            alt="logo"
          />
        </div>
      </div>
    </section>
  );
}

export default Featuredin;
