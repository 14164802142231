import "./style.css";

function ResourcesSection() {
  // waitlist to be used by different componets we had to place this on top
  return (
    <section className="section-services" id="services-sec">
      <div className="container" id="services-cont">
        <span className="subheading" id="subheading-id">
          Services
        </span>
        <h2 className="heading-secondary">
          Choose C Cube services which are tailored to accelarate your career!
        </h2>
      </div>

      <div className="container grid grid-3-cols">
        <div className="services" id="services-id">
          <img
            src="content/img/meals/service-1.jpg"
            className="services-im"
            id="servicesimg-id"
            alt="from unsplash complete credtis to them"
          />
          <div className="services-content" id="services-content-id">
            <p className="service-title" id="service-title-id">
              Automated job workflows
            </p>
            <ul className="service-attributes" id="service-attributes-id">
              <li className="service-attribute" id="service-attribute-id">
                <ion-icon name="star-outline"></ion-icon>
                <span>Streamlined Job Search</span>
              </li>
              <li className="service-attribute" id="service-attribute-id">
                Expediated Job Applications
              </li>
              <li className="service-attribute" id="service-attribute-id">
                Robust Referral Program
              </li>
              <a href="#" className="btn btn--full margin-rihgt-sm">
                Learn more
              </a>
            </ul>
          </div>
        </div>

        <div className="services" id="services-id">
          <img
            src="content/img/meals/service-2.jpg"
            className="services-im"
            id="servicesimg-id"
            alt="from unsplash complete credtis to them"
          />
          <div className="services-content" id="services-content-id">
            <p className="service-title" id="service-title-id">
              Community of industry experts
            </p>
            <ul className="service-attributes" id="service-attributes-id">
              <li className="service-attribute" id="service-attribute-id">
                <ion-icon name="star-outline"></ion-icon>
                <span>Industry Partnerships</span>
              </li>
              <li className="service-attribute" id="service-attribute-id">
                Real World Experience
              </li>
              <li className="service-attribute" id="service-attribute-id">
                Stealth mode startup partnerships
              </li>
              <a
                href="https://shareafact-nav.netlify.app/"
                className="btn btn--full margin-rihgt-sm"
              >
                Learn more
              </a>
            </ul>
          </div>
        </div>

        <div className="listofservices">
          <p className="s-text" id="s-text-id">
            List of Services coming soon 🚀
          </p>
          <ul className="list-services" id="list-services-id">
            <li className="comingsoon-service" id="comingsoon-service-id">
              Overcome Visa hurdles
            </li>

            <li className="comingsoon-service" id="comingsoon-service-id">
              Resume/research paper Builder
            </li>
            <li className="comingsoon-service" id="comingsoon-service-id">
              LinkedIn Optimization
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ResourcesSection;
