import React from "react";
import YouTube from "react-youtube";

function VideoPlayer() {
  const videoId = "Gjx9NJntwGg";

  return (
    <section className="vplayer" id="vplayer-id">
      <div className="container">
        <p className="vplayer-text">Learn More about C Cube</p>
        <div className="theplayer">
          <YouTube videoId={videoId} />
        </div>
      </div>
    </section>
  );
}

export default VideoPlayer;
